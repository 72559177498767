export function setCache(key, value) {
    return localStorage.setItem(key, value);
}

export function getCache(key) {
    const value = localStorage.getItem(key);
    try {
        return JSON.parse(value)
    } catch (e) {
        return value;
    }
}

export function clearCache() {
    localStorage.clear()
}
/**
 * 微信
 */
// 判断是否是微信浏览器的函数
export function isWeiXin() {
    // window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var userAgent = window.navigator.userAgent.toLowerCase();
    // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (userAgent.indexOf('micromessenger') !== -1) {
        // 在微信浏览器中打开
        return true
    } else {
        // 不在微信浏览器中打开
        return false
    }
}
