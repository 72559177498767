import axios from 'axios'
import settings from "@/config/settings";
import router from '@/router'
import {clearCache, getCache} from "@/utils/common";
import {message} from "ant-design-vue";

const request = axios.create({
    baseURL: settings.gateway,
})
request.interceptors.request.use(
    (config) => {
        const token = getCache('token')
        token && (config.headers['token'] = token)
        return config
    }
)
request.interceptors.response.use(function (response) {
    //请求成功的拦截
    if (response.data.code === "00401") {
        message.error(response.data.message)
        router.replace({name: 'Login'})
        clearCache()
    } else if (response.data.code === "00403" || response.data.code === "00404") {
        message.error(response.data.message)
    }
    return response
})

export default request
